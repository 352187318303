import { FC, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Box } from '@mui/system'
import { S } from './topupcard.style'
import esimImg from 'assets/esimImg.svg'
import networkImg from 'assets/networkImg.svg'
import ValidTime from 'assets/ValidTime.svg'
import activateImg from 'assets/ActivateImg.svg'
import GiantDollor from 'assets/icons/GiantDollor.svg'
import GlobeIcon from 'assets/icons/GlobelIcon.svg'
import coverageIcon from 'assets/icons/CoverageIcon.svg'

import { useWalletContext } from 'contexts/wallet.context'
import { useRewardPercentage } from 'hooks/rewardPercentage.hook'
import { countriesWithPlaceholderFlag, PURCHASE_TYPE_BUY, PURCHASE_TYPE_GIFT } from 'utils/constants'
import { useLocation } from 'react-router-dom'
import { useAppContext } from 'contexts/app.context'
import CustomTooltip from 'components/tooltip'
import { useModalContext } from 'contexts/modal.context'
import { userClient } from 'services/http/user.service'
import { usePaymentContext } from 'contexts/payment.context'

interface Props {
  offer: any
  index: any
}

const TopupCard: FC<Props> = ({ offer, index }) => {
  const { modalDataHandler, demandReward, allProviders, setShowPurchaseModal, setPurchaseModalType, userLogin } =
    useWalletContext()
  const { selectedCountry, selectedOfferType, setIsTopup } = useAppContext()
  const { setShowNetworkModal } = useModalContext()
  const { credits } = usePaymentContext()
  const [filteredCoverage, setFilteredCoverage] = useState<any>([])

  const currentPath = useLocation()
  let path = currentPath.pathname

  let packSize = (offer?.quantityOfDataInBytes / (1024 * 1024) / 1024).toString()
  let finalPackSize = packSize.length > 3 ? parseFloat(packSize).toFixed(2) : packSize

  let rewardPercentage = useRewardPercentage()
  //  This useEffect will filter offers coverage based on poolId
  useEffect(() => {
    handleCoverageData()
  }, [offer])

  const handleCoverageData = async () => {
    let temp = await offer.coverage.filter((coverage: any) => coverage.poolId === offer.poolId)
    setFilteredCoverage(temp)
  }

  const filterCoveragetworks = (limit: any) => {
    let filterValueofCoverage = filteredCoverage[0]?.coverage?.map((item: any) =>
      item?.networks?.map((network: any) => network?.name),
    )

    return limit === 0 ? filterValueofCoverage : filterValueofCoverage ? filterValueofCoverage[0][0] : null
  }

  // This function will return the provider for plan
  const provider = allProviders?.find((item: any) => {
    return item._id === (offer?.providerId?.id ? offer?.providerId?.id : offer?.providerId)
  })

  let segmentData = {
    name: offer?.name,
    description: offer?.description,
    activationPeriod: offer?.activationPeriod,
    networkType: offer?.networkType,
    offerId: offer?.offerId,
    PurchaseType: offer?.type,
    poolId: offer?.poolId,
    providerId: offer?.providerId,
    quantityOfDataInBytes: offer?.quantityOfDataInBytes,
    region: offer?.region,
    country: offer?.regionId[0]?.name,
    retailPrice: selectedOfferType ? offer?.resalePrice : offer?.retailPrice,
    validFromActivation: offer?.validFromActivation,
  }

  const handleModal = (type: any) => {
    setShowPurchaseModal(true)
    setPurchaseModalType(type)
    let manipulatedData = {
      ...offer,
      country: offer?.countryCode,
      color: offer?.countryColor?.metadata,
      quantity:1,
      totalPurchaseAmount:offer?.retailPrice?offer?.retailPrice:0,
      flagCountryCode: offer?.flagCountryCode,
    }
    modalDataHandler({
      data: manipulatedData,
      modalCountryData: offer,
    })

    if (userLogin) {
      userClient.postUserEventsWithAuth(
        {
          eventName: `Purchase Topup button clicked `,
          properties: {
            offer: segmentData,
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': `${path === '/mypage' ? 'MyPage' : 'Home'}`,
          },
        },
        userLogin,
      )
    }
  }

  const handleOpenNetworkModal = (type: string) => {
    setShowNetworkModal({
      data: filteredCoverage,
      type: type,
      isOpen: true,
      offer: offer,
    })
  }

  return (
    <motion.div whileHover={{ scale: 1.05 }}>
      <S.TopupCardContainer>
        {/*{selectedCountry?.value !== undefined && selectedCountry !== null && (index === 0 || index === 1) && (
          <S.BestDealChip>Best deal</S.BestDealChip>
        )}*/}
        <S.TopupCardHeader
          gradientstart={
            offer?.countryColor?.metadata?.gradientstart ? offer?.countryColor?.metadata?.gradientstart : '#CFEBE1'
          }
          gradientend={
            offer?.countryColor?.metadata?.gradientend ? offer?.countryColor?.metadata?.gradientend : '#F0F9F6'
          }
        >
          <S.HeaderDataContainer>
            <S.DataValueText>{finalPackSize}&nbsp;</S.DataValueText>GB |{' '}
            {selectedOfferType ? offer?.validity : offer?.validFromActivation} days
          </S.HeaderDataContainer>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <S.CountryText>
              {offer?.countryCode.length < 10 ? offer?.countryCode : offer?.countryCode.slice(0, 9) + '..'}
            </S.CountryText>
            {countriesWithPlaceholderFlag.includes(offer?.flagCountryCode) ? (
              <img src={GlobeIcon} alt="" />
            ) : (
              <img
                alt="country flag"
                style={{ height: '2rem', width: '2rem' }}
                src={`https://giant-protocol.github.io/country-flag-icons/3x2/${offer?.flagCountryCode.toUpperCase()}.svg`}
              />
            )}
          </Box>
        </S.TopupCardHeader>
        <S.TopupCardBody>
          <S.ProviderDetailsSection>
            <S.TopupCardSubBody>
              <img src={esimImg} alt="esimimage" />
              <S.ProviderWrapper>
                <S.SubBodyText>Provider</S.SubBodyText>
                <S.ProviderContainer>
                  <img
                    src={`data:image/png;base64,${provider?.logo}`}
                    alt="logo"
                    style={{ width: '0.9706rem', height: '1rem' }}
                  />
                  <S.ProviderValueText>
                    {provider?.displayName.length > 10
                      ? `${provider?.displayName.slice(0, 6)}...`
                      : provider?.displayName}
                  </S.ProviderValueText>
                </S.ProviderContainer>
              </S.ProviderWrapper>
            </S.TopupCardSubBody>
            <S.TopupCardSubBody>
              <img src={networkImg} alt="esimimage" />
              <S.ProviderWrapper>
                <S.SubBodyText>Network</S.SubBodyText>
                <S.ProviderValueText>
                  {offer?.type === 'self' ? (
                    <CustomTooltip message={filterCoveragetworks(1)}>
                      <S.SubBodyOverFlowText>{filterCoveragetworks(1)} </S.SubBodyOverFlowText>
                    </CustomTooltip>
                  ) : (
                    <Box
                      onClick={() => handleOpenNetworkModal('network')}
                      sx={{ display: 'flex', gap: '0.5rem', cursor: 'pointer', color: '#45B549' }}
                    >
                      Coverage
                      <S.CoverageIconImg src={coverageIcon} alt="coverage icon" />
                    </Box>
                  )}
                </S.ProviderValueText>
              </S.ProviderWrapper>
            </S.TopupCardSubBody>
          </S.ProviderDetailsSection>

          {selectedOfferType ? (
            <S.ProviderDetailsSection sx={{ paddingTop: '1.25rem !important', borderBottom: 'unset !important' }}>
              <S.TopupCardSubBody>
                <img src={activateImg} alt="esimimage" />
                <S.ProviderWrapper>
                  <S.SubBodyText>{offer?.activationPeriod === 0 ? 'Activate' : 'Activate within'}</S.SubBodyText>
                  <S.ProviderContainer>
                    <S.ProviderValueText>
                      {offer?.activationPeriod === 0 ? 'Immediately' : offer?.activationPeriod}{' '}
                      {offer?.activationPeriod !== 0 && (offer?.activationPeriod === 1 ? 'day' : 'days')}
                    </S.ProviderValueText>
                  </S.ProviderContainer>
                </S.ProviderWrapper>
              </S.TopupCardSubBody>
              <S.TopupCardSubBody>
                <img src={ValidTime} alt="esimimage" />
                <S.ProviderWrapper>
                  <S.SubBodyText>Valid for</S.SubBodyText>
                  <S.ProviderValueText>
                    {offer?.validFromActivation !== undefined ? offer?.validFromActivation : offer.validity}{' '}
                    {offer?.validFromActivation !== undefined
                      ? offer?.validFromActivation === 1
                        ? 'day'
                        : 'days'
                      : offer?.validity === 1
                      ? 'day'
                      : 'days'}
                  </S.ProviderValueText>
                </S.ProviderWrapper>
              </S.TopupCardSubBody>
            </S.ProviderDetailsSection>
          ) : (
            <S.PaymentSection>
              <S.PaymentSubBody>
                <S.PaymentText>Pay</S.PaymentText>
                <S.PriceText>${Number(offer?.retailPrice)?.toFixed(2)}</S.PriceText>
              </S.PaymentSubBody>
              <S.PaymentSubBody>
                {selectedOfferType ? (
                  <S.PaymentText>Earn</S.PaymentText>
                ) : (
                  <S.PaymentText>
                    Earn (<S.PercentageText>{rewardPercentage}% back</S.PercentageText>)
                  </S.PaymentText>
                )}
                <S.RewardsContainer>
                  <img src={GiantDollor} alt="GiantDollor" style={{ height: '22.24px' }} />
                  <S.PriceText>{selectedOfferType ? '-' : Number(offer?.retailPrice * demandReward).toFixed(2)}</S.PriceText>
                </S.RewardsContainer>
              </S.PaymentSubBody>
            </S.PaymentSection>
          )}
        </S.TopupCardBody>
          <S.TopupCardFooter>
            <S.TopupButton onClick={() => {
              setIsTopup(true)
              handleModal(PURCHASE_TYPE_BUY); 
             }}>
              Topup
            </S.TopupButton>
          </S.TopupCardFooter>        
      </S.TopupCardContainer>
    </motion.div>
  )
}

export default TopupCard
