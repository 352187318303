import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace appClient {
  export const getAppId = () => {
    return httpClient.get(`appconfig`)
  }

  export const signIn = (data: any) => {
    return httpClient.post(`accounts/login`, {
      data,
    })
  }
  export const verification = async (id: string) => {
    return await httpClient.get(`devices/emailVerification/${id}`)
  }  
}
