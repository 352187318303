import styled from '@emotion/styled'
import { Box, ToggleButton } from '@mui/material'

export namespace T {
  export const CustomToggleBtn = styled(ToggleButton)(({ theme }: any) => ({
    height: '2.5rem',
    width: '8rem',
    textTransform: 'none',
    background: '#0b213e0a !important',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    color: 'rgba(61, 61, 61, 0.5)',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#45B549',
      border: '1.4px solid #45B549 !important',
      background: '#fff !important',
      borderRadius: '6px !important',
      boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.2)',
    },
  }))

  export const ButtonsContainer = styled(Box)(({ theme,  paymentstyle }: any) => ({
    height: '45px',
    display: 'flex',
    gridTemplateColumns: '120px 1fr 1fr 1.5fr',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      order: 2,
      padding: '1rem 0 0.5rem 0',
      display: paymentstyle === 'true' ? 'none' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))

  export const Toggler = styled(Box)(({ theme, selected,disabled }: any) => ({
    display: 'flex',
    border: '2px solid black',
    padding: '0.5rem',
    minWidth: '100px',
    maxWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    background: selected ? '#fff' : 'rgba(60, 60, 67, 0.05)',
    borderColor: selected ? '#45B549' : 'rgba(60, 60, 67, 0.06)',
    color: selected ? '#45B549' : 'rgba(61, 61, 61, 0.5)',
    cursor: disabled === true ? 'not-allowed' :'pointer',
    fontSize: '1.125rem',
    fontWeight: 500,
    borderRadius: '0.5rem',
    zIndex: selected ? 2 : 'unset',
  }))
}
