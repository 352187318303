import React, { FC } from 'react'
import { PAYMENT_TYPE, BULK_PAYMENT_TYPE,PURCHASE_TYPE_BULK_BUY,PURCHASE_TYPE_SINGLE_BUY } from 'utils/constants'
import { usePaymentContext } from 'contexts/payment.context'
import { T } from './styled'
interface Props {
  isDisable: boolean
}

const PaymentToggle: FC<Props> = ({ isDisable }) => {
  const { paymentType, paymentSuccess, purchaseOrderType,setPaymentType } = usePaymentContext()

  const handleToggle = (value: string) => {
    setPaymentType(value)
  }
  const hideCardPayment = (process.env.REACT_APP_CREDITCARD_PAYMENT === 'false'); 
  const hideCirclePayment = (process.env.REACT_APP_CIRCLE_PAYMENT === 'false'); 
  const hideCoinbasePayment = (process.env.REACT_APP_COINBASE_PAYMENT === 'false');
  const hideCTOPayment = (process.env.REACT_APP_CRYPTOPAY_PAYMENT === 'false');
  return (
    
    <T.ButtonsContainer paymentstyle={paymentSuccess?'true':'false'}>
      {!paymentSuccess && (purchaseOrderType === PURCHASE_TYPE_SINGLE_BUY) &&
         PAYMENT_TYPE.map((item, i) => (<>
          {(!hideCardPayment && item.value ==='creditcard')||(!hideCirclePayment && item.value ==='crypto')||(!hideCoinbasePayment && item.value ==='coinbase')||(!hideCTOPayment && item.value ==='cto')?
          <T.Toggler
            key={item.value}
            selected={paymentType === item.value}
            disabled={isDisable}
            onClick={() => isDisable === false && handleToggle(item.value)}
          >
            {item.label}
            </T.Toggler>:null
        }</>
        ))}
        {!paymentSuccess && (purchaseOrderType === PURCHASE_TYPE_BULK_BUY) &&
          BULK_PAYMENT_TYPE.map((item, i) => (<>
            {(!hideCardPayment && item.value ==='creditcard')||(!hideCirclePayment && item.value ==='crypto')||(!hideCoinbasePayment && item.value ==='coinbase')?
            <T.Toggler
              key={item.value}
              selected={paymentType === item.value}
              disabled={isDisable}
              onClick={() => isDisable === false && handleToggle(item.value)}
            >
              {item.label}
              </T.Toggler>:null
            }</>
        ))}
    </T.ButtonsContainer>
  )
}

export default PaymentToggle
