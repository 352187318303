import React, { FC, useEffect, useState } from 'react'
import Select, { components, ControlProps, NoticeProps } from 'react-select'
import { Box, CircularProgress } from '@mui/material'
import { usePaymentContext } from 'contexts/payment.context'
import DownArrow from 'assets/icons/DownArrow.svg'

interface Props {
  selectedPayment: any
  setSelectedPayment: any
  isDisable: boolean
}
const PaymentDropDown: FC<Props> = ({ isDisable, selectedPayment, setSelectedPayment }) => {
  const { SingleValue, Option } = components
  const [manipulatedCryptos, setManipulatedCryptos] = useState<any>([])
  const { cryptos } = usePaymentContext()

  useEffect(() => {
    if (cryptos.length > 0) {
      //let temp = cryptos.filter((crypto: any) => crypto.chain === 'ETH' || crypto.chain === 'SOL')
      //setManipulatedCryptos(temp)
      setManipulatedCryptos(cryptos)
    }
  }, [cryptos])

  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <>
        <components.Control {...props}>
          {children} <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    )
  }
  const IconSingleValue = (props: any) => (
    <SingleValue {...props}>
      {props.data.logo && (
        <img src={props.data.logo} style={{ height: '23px', width: '23px', marginRight: '10px' }} alt="" />
      )}
      <p>{props.data?.value?props.data.value:props.data.chainName}</p>
      {/* {props.data.label} */}
    </SingleValue>
  )

  const IconOption = (props: any) => (
    <Option {...props}>
      {props.data.logo && (
        <img src={props.data.logo} style={{ height: '23px', width: '23px', marginRight: '10px' }} alt="" />
      )}
      <p>{props.data?.value?props.data.value:props.data.chainName}</p>
      {/* {props.data.label} */}
    </Option>
  )

  const NoOptionsMessage = (props: NoticeProps) => {
    return manipulatedCryptos.length !== 0 ? (
      <components.NoOptionsMessage {...props} />
    ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem' }}>
        <CircularProgress size={30} />
      </Box>
    )
  }

  return (
    <Select
      options={manipulatedCryptos}
      value={selectedPayment}
      onChange={setSelectedPayment}
      isSearchable={false}
      isDisabled={isDisable === true}
      components={{ Control: Control, SingleValue: IconSingleValue, Option: IconOption, NoOptionsMessage }}
      // components={{ Control }}
      styles={{
        control: (base:any) => ({
          ...base,
          background: '#ced6e040',
          border: 'none',
          borderRadius: '8px',
          padding: '0 0.8rem',
          width: '100%',
          minHeight: '43px',
          outline: 'none',
          boxShadow: 'none',
          cursor: isDisable === false ? 'pointer' : 'not-allowed !important',
        }),
        singleValue: (base:any) => ({
          ...base,
          color: '#66717B',
          fontSize: '18px',
          lineHeight: '8px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          cursor: isDisable === false ? 'pointer' : 'not-allowed !important',
        }),
        option: (base:any) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
          color: 'rgba(11, 33, 62, 0.6)',
          fontSize: '14px',
          lineHeight: '10px',
          borderBottom: '1px solid rgba(222, 231, 253, 0.6)',
          backgroundColor: 'none',
          paddingLeft: '1.3rem',
          zIndex: '100 !important',
          ':hover': {
            background: 'none',
            color: '#45B549',
          },
          ':active': {
            background: 'none',
            color: '#45B549',
          },
        }),
        indicatorsContainer: (base:any) => ({
          ...base,
          display: 'none',
        }),
        menu: (base:any) => ({
          ...base,
          maxWidth: '100%',
          background: '#FFFFFF',
          border: '1px solid #D5E1FF',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
          zIndex: '100 !important',
        }),
        menuList: (base:any) => ({
          ...base,
          maxHeight: '200px !important',
          zIndex: '100 !important',
          '::-webkit-scrollbar': {
            width: '4px',
            height: '0px',
          },
          '::-webkit-scrollbar-track': {
            background: '#DEE7FD',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#45B549',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#45B549',
          },
        }),
      }}
    />
  )
}

export default PaymentDropDown
