import styled from '@emotion/styled'
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Input, Stepper, Typography, } from '@mui/material';
import Select from '@mui/material/Select';
// import { lineHeight, typography } from '@mui/system'
// import BgImg from 'assets/ShopHeaderBg.png'
import { Link } from 'react-router-dom'

export namespace S {
  export const LinkText = styled(Typography)`
    font-size: 1.25rem;
  `
  export const LinkButton = styled(IconButton)`
    padding: 3px !important;
  `

  export const HyperLink = styled.a(
    ({ theme }: any) => `
    color: ${theme.palette.primary.main};
    text-decoration: none;
    cursor: pointer;
    display:inline-flex;

  `,
  )

  export const CoverageMapLink = styled(Box)(({ theme }: any) => ({
    fontSize: '16px',
    fontWeight: '700',

    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    a: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  }))

  export const DataValueText = styled(Typography)(({ theme }: any) => ({
    fontSize: '3.25rem',
    fontWeight: '700',
    color: '#131A22',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
  }))
  export const DataText = styled(DataValueText)(({ theme }: any) => ({
    fontSize: '2.25rem',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  }))
  export const DaysText = styled(Typography)(({ theme }: any) => ({
    paddingTop: '1rem',
    fontWeight: 700,
    fontSize: '18px',
    color: '#0B213E',
    display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '15px',
    // },
  }))
  export const DestinationText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2rem',
    color: '#0B213E',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      // width: '5.632rem',
      // textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
  }))

  export const ModalHeader = styled(Box)(({ theme }: any) => ({
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px 8px 0px 0px !important',
    // gap: '0.5rem',
  }))
  export const ModalSubHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '0.4rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  }))
  export const ModalHeading = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '3.25rem',
    color: '#131A22',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  }))
  export const ModalHeaderCountry = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2rem',
    color: '#0B213E',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5625rem',
    },
  }))
  export const ModalSubHeadingGB = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '2.25rem',
    paddingTop: '1rem',
    color: '#131A22',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      fontSize: '1.75rem',
    },
  }))
  export const ModalHeaderCoverageMap = styled(Typography)(({ theme }: any) => ({
    paddingTop: '1rem',
    fontWeight: 700,
    fontSize: '1.125rem',
    // lineHeight: '23px',
    color: '#0B213E',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      flexWrap: 'wrap',
    },
  }))

  export const DctStatusModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  }))

  export const ModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px !important',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      // height: '70.5%',
      width: '77%',
      // maxHeight: 530,

      maxWidth: 970,
    },

    [theme.breakpoints.down('lg')]: {
      // height: '56.5%',
      width: '90%',
      minHeight: 530,
      // background:'orange',

      minWidth: 900,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '98vh',
      minWidth: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  }))
  export const PurchaseModalWrapper = styled(Box)(({ theme }: any) => ({
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 4fr',
  }))

  export const ShareModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    // minWidth: '50%',
    // height: 502,
    maxHeight: '80%', //502,
    overflowX: 'hidden',
    overflowY: 'auto',
  }))
  export const ShareModalTitle = styled(Box)(({ theme }: any) => ({
    position: 'absolute',    
    left: '1rem',
    color: '#4f4f4f',
    top: '0.625rem',
    right: '0.3125rem',
    fontSize: '18px',
    fontWeight: '600',

  }))
  export const ManageModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    width: '65vw',
    height: '90vh',
    outline: 'none',
    borderRadius: '8px !important',
    padding: '0.2rem',
    display: 'grid',
    gridTemplateRows: '5rem 5rem 1fr 5rem',
  }))
  export const ManageModalHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    height: '5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  }))
  export const ManageModalHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '31px',
    textAlign: 'left',
    paddingLeft: '1rem',
  }))
  export const ManageModalBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    padding: '0 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.grey[400],
    // overflow: 'auto',
    // maxHeight: 'calc(100% - 5rem)',
    // maxHeight: '37.5rem',
    // height: '51.5vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  }))
  export const CustomIconButton = styled(IconButton)(({ theme }: any) => ({
    padding: '0 !important',
    marginLeft: '1.6875rem',
    display: 'grid',
    placeContent: 'center',
    '&:hover': {
      background: 'none',
    },
    svg: {
      color: '#000',
      padding: '0 !important',
      borderRadius: '50%',
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
  }))
  export const ModalBody = styled(Box)(({ theme }: any) => ({
    gap: '1.5rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //   height: '74vh',
    // },
  }))
  export const DetailsContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '3fr 1.5fr',
    gap: '1rem',
    flexWrap: 'wrap',
    width: 'unset',
    alignSelf: 'flex-end',
    padding: '1rem 2rem 1rem 2.875rem',
    minHeight: '26.5rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'unset',
      padding: '1rem 2rem',
    },
    [theme.breakpoints.down(510)]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '47rem',
    },
    [theme.breakpoints.down(395)]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '44rem',
      maxHeight: '80vh',
    },
    [theme.breakpoints.down(376)]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '31rem',
    },
  }))
  export const DetailsIllustrationImg = styled('img')(({ theme, medium1 }: any) => ({
    [theme.breakpoints.down('md')]: {
      display: medium1 ? 'flex' : 'none',
    },
  }))
  export const DetailsMainText = styled(Typography)(({ theme }: any) => ({
    fontSize: '14px',
    lineHeight: '18px',
    color: '#0B213E',
    fontWeight: '400',
  }))
  export const DetailsSubText = styled(Box)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#0B213E',
    display: 'flex',
  }))
  export const DetailsPayText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    color: '#0B213E',
    fontSize: '18px',
    lineHeight: '23px',
  }))
  export const CreditsPayText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    color: '#0B213E',
    fontSize: '16px',
    lineHeight: '21px',
  }))
  export const CreditsPayContainer = styled(Box)(({ theme }: any) => ({
    display:"flex",
    justifyContent:"space-between",
    width:"90%"
  }))
  export const DetailsEarnText = styled(DetailsPayText)(({ theme }: any) => ({
    fontWeight: '700',
    color: theme.palette.primary.main,
    fontSize: '24px',
    lineHeight: '31px',
    display: 'flex',
    alignItems: 'center',
  }))
  export const DetailsGreyText = styled(DetailsMainText)(({ theme }: any) => ({
    fontWeight: '400',
    lineHeight: '1.3125rem',
    color: '#66717B',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '1rem 0',
    },
  }))
  export const DetailsGreyTextTerm = styled(DetailsMainText)(({ theme }: any) => ({
    fontWeight: '400',
    lineHeight: '1.3125rem',
    color: '#66717B',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))
  export const ContentContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem 0',
    // flexWrap:"wrap",
  }))
  export const ChoosePaymentContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  }))
  export const PayEarnContainer = styled(Box)(({ theme, medium1 }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '85%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      width: medium1 && '100%',
      display: medium1 ? 'flex' : 'none',
    },
  }))
  export const PayEarnMainContainer = styled(Box)(({ theme }: any) => ({
    height: '8rem',
    display: 'flex',
    alignItems: 'center',
  }))

  export const ImgContainer = styled(Box)(({ theme, medium1 }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '1rem 0 2rem 1rem',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: '0',
    },
  }))
  export const DataWrapper = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '30%',
    gap: '0.5rem',
  }))
  export const DataContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: '2rem',
    gap: '1.5rem',
    borderBottom: '1px solid #DEE7FD',
  }))
  export const CustomLink = styled(Link)(({ theme }: any) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '700',
  }))
  export const TitleContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    '@media (max-width: 576px)': {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }))
  export const TitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
  }))
  export const ValueText = styled(TitleText)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.5rem',
  }))
  export const DynamicContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '75%',
    paddingTop: '1.5rem',
  }))
  export const CustomDynamicContainer = styled(Grid)(({ theme }: any) => ({
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexWrap: 'wrap',
    // height: '100%',
    height: 'cal(502px - 84px)',
    padding: '1rem 4rem 1.5rem 4rem',
    gap: '2rem',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
    },
  }))
  export const ActivationSuccessContainer = styled(Grid)(({ theme }: any) => ({
    height: 'cal(502px - 84px)',
    padding: '2rem 2rem 1.5rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
    },
  }))

  export const ActivePeriodTextContainer = styled(Typography)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  }))
  export const BoldText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '23px',
  }))
  export const SuccessTypography = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
  }))
  export const SuccessSubTypography = styled(SuccessTypography)(({ theme }: any) => ({
    fontWeight: 400,
  }))

  export const ModalFooterText = styled(Box)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '15px',
    color: 'rgba(65, 65, 65, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  }))

  export const ModalWarning = styled(Box)(({ theme }: any) => ({
    background: ' rgba(255, 196, 48, 0.28)',
    color: '#FF7A00',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    position: 'absolute',
    width: '100%',
  }))

  export const GiftModalBody = styled(Box)(({ theme }: any) => ({
    minHeight: 'calc(502px - 84px)',
    disply: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'space-between',
    padding: '2.2rem',
    boxSizing: 'border-box',
  }))
  export const GiftModalContainerBox = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    placeContent: 'center',
    padding: '20px',
  }))
  export const GiftModalContainerBoxLeft = styled(Box)(({ theme }: any) => ({
    height: '35vh',
    width: '50%',
    minWidth: '300px',
    display: 'grid',
    placeContent: 'center',
  }))
  export const GiftModalContainerBoxRight = styled(Box)(({ theme }: any) => ({
    height: '35vh',
    width: '50%',
    minWidth: '300px',
    display: 'grid',
    placeContent: 'center',
  }))
  export const GiftModalText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'center',
    // padding: '1rem 0',
    boxSizing: 'border-box',
  }))
  export const GiftModalSubText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
  }))
  export const GiftModalSubText2 = styled(Typography)(({ theme }: any) => ({
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'center',
  }))
  export const GiftSuccessContainer = styled(CustomDynamicContainer)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }))
  export const GiftCheers = styled(Box)(({ theme }: any) => ({
    width: '50%',
    display: 'grid',
    placeContent: 'center',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  }))

  export const SuccessContainer = styled(Box)(({ theme }: any) => ({
    background: '#f3f5f7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    '@media (min-width: 876px)': {
      height: '13rem',
      width: '46.4375rem',
    },
    '@media (max-width: 876px)': {
      maxWidth: '80%',
      padding: '4rem',
      textAlign: 'center',
    },
  }))
  export const SuccessText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.5rem',
  }))
  export const StatusText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: 400,
    marginTop: '1rem',
  }))

  export const NotDoneContainer = styled(Box)(({ theme }: any) => ({
    // width: '50%',
    // padding: '2rem 0 0rem 4rem',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-around',
    // gap: '3rem',
    // minHeight: '256px',
    // boxSizing: 'border-box',
  }))
  export const ShareHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#414141',
  }))
  export const ShareHeaderSubText = styled(ShareHeaderText)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    marginTop: '0.2rem',
  }))
  export const AggreeText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    color: '#0B213E',
    paddingLeft: '5px',
  }))
  export const RoundWrapper = styled(Box)(({ theme }: any) => ({
    background: '#00C853',
    width: '2rem',
    height: '2rem',
    display: 'grid',
    borderRadius: '50%',
    placeContent: 'center',
    color: theme.palette.primary.contrastText,
  }))
  export const TextWrapper = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '3rem 90%',
    position: 'relative',
    minWidth: '300px',
    margin: '1rem 0',
    // gap:"1rem"
  }))
  export const AggrementText = styled(AggreeText)(({ theme }: any) => ({
    color: '#66717B',
  }))
  export const NotDoneText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.5rem',
    fontWeight: 700,
  }))

  export const InstructionText = styled(NotDoneText)(({ theme }: any) => ({
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
  }))

  export const ActivationText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: 400,
  }))

  export const MainContent = styled(Box)(({ theme }: any) => ({
    gap: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))
  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2.625rem',
    lineHeight: '3.4375rem',
    color: theme.palette.text.primary,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
    },
  }))
  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: theme.palette.common.white,
    minWidth: '12rem !important',
    height: '50px',
    boxShadow: 'none',
    border: 'none',
    fontWeight: 700,
    padding: '0rem 0.625rem',
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '12rem !important',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '12rem !important',
    },
  }))  
  export const CheckBox = styled.input(({ theme }: any) => ({
    width: '21px',
    height: '21px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
  }))

  export const CongratsContainer = styled(Box)(({ theme }: any) => ({
    height: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }))
  export const CongratsText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  }))
  export const NoteText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '17px',
  }))
  export const CongratsSubText = styled(Link)(({ theme }: any) => ({
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  }))
  export const BtnContainer = styled(Box)(({ theme }: any) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
  }))

  export const ActivateCongratsText = styled(Typography)(({ theme }: any) => ({
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28px',
    textAlign: 'center',
  }))
  export const ActivateCongratSubText = styled(Box)(({ theme }: any) => ({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#66717B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  export const CustomModalBody = styled(ModalBody)(({ theme }: any) => ({
    padding: '2rem 4rem 2rem 4rem',
    display: 'flex',
    height: 'calc(502px - 84px)',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }))
  export const ActivateContainer = styled(Box)(({ theme }: any) => ({
    // width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '200px',
    maxHeight: '200px',
  }))
  export const ActivateSubContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    },
  }))

  export const ShareBody = styled(CustomModalBody)(({ theme }: any) => ({
    height: 504,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    padding: '4rem',
  }))
  export const CopyInput = styled.input(({ theme }: any) => ({
    background: '#f4f5f7',
    color: theme.palette.primary.main,
    width: '90%',
    border: 'none',
    borderRadius: '10px 0 0 10px',
    padding: '1rem 2rem 1rem 3rem',
    fontSize: '1.125rem',
    // borderLeft:"1px solid red",
    fontWeight: 700,
    height: '47px',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
  }))
  export const CopyContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    position: 'relative',
    width: '100%',
  }))
  export const CopyIconContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    placeContent: 'center',
    width: '2.9375rem',
    // height: '100%',
    borderRadius: '0 10px 10px 0',
    background: theme.palette.primary.main,
  }))
  export const LinkIconContainer = styled(Box)(({ theme }: any) => ({
    // display: 'grid',
    // placeContent: 'center',
    // padding: '16px',
    // background: '#f4f5f7',
    height: '15px',
    left: '1rem',
    position: 'absolute',
    top: '33%',
    borderRight: '1px solid #C3C4C6',
    // borderRadius: '10px 0 0 10px',
  }))
  export const ShareIconContainer = styled(Box)(({ theme }: any) => ({
    height: '2.9375rem',
    width: '2.9375rem',
    display: 'grid',
    placeContent: 'center',
    background: theme.palette.primary.main,
    borderRadius: '10px',
    marginLeft: '10px',
  }))
  export const CustomMediaButton = styled.button(
    () => `
   display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  width: 48px
  height: 48px
  border-radius: 50%
  background: #E6E8EC
  transition: all .2s
  svg
      transition: fill .2s
  &:hover
      background: #3772FF
      svg
          fill: #FCFCFD
  +dark
      background: #353945
      svg
          fill: #FCFCFD
      &:hover
          background: #3772FF
          svg
              fill: #FCFCFD
    &:not(:last-child)
        margin-right: 24px
  `,
  )

  export const CardContents = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      gap: '2.5rem',
    },
    [theme.breakpoints.between(420, 720)]: {
      display: 'grid !important',
      gridTemplateColumns: '1fr 1fr',
      gap: '2rem',
    },
  }))

  export const CopiedButton = styled(IconButton)(({ theme }: any) => ({
    '&:hover': {
      background: theme.palette.primary.main,
    },
    svg: {
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  }))

  export const ConfirmContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '99',
    borderRadius: '5px',
    outline: 'none',
    '@media (max-width: 980px)': {
      width: '80%',
      overflowY: 'hidden',
    },
  }))

  export const WarningText = styled(Typography)(({ theme }: any) => ({
    display: 'flex',
    justifySelf: 'center',
    padding: '0 1rem',
    alignSelf: 'center',
    fontSize: '1.25rem',
  }))

  export const ConfirmModalFooter = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    padding: '1rem',
    alignItems: 'center',
  }))

  export const PurchaseInfoHeaderContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    boxSizing: 'border-box',
    borderRadius: '0.5rem 0.5rem 0px 0px !important',
    gridTemplateColumns: '95% 5%',
    padding: '0 1.6875rem 0 3rem',
    minHeight: '84px',

    [theme.breakpoints.down('sm')]: {
      // minHeight: '100px',
      padding: '0 2.6875rem 0 1rem',
    },

    [theme.breakpoints.down(395)]: {
      minHeight: '125px',
    },
  }))

  export const CustomInput = styled(Input)(({ theme }: any) => ({
    background: '#ced6e040',
    color: '#66717B',
    borderRadius: '0.5rem',
    input: {
      color: '#000',
      padding: '0.75rem',
    },
    '&::placeholder': {
      color: '#66717B',
    },
  }))
  export const CustomSelectBox = styled(Select)(({ theme }: any) => ({   
    color: '#66717B',
    borderRadius: '0.5rem',
    width:'100%',
    height:'30px',
    input: {
      color: '#000',
      padding: '0.75rem',
    },    
    marginBottom:'0.75rem',
  }))
  export const DepositAddressFooterContainer = styled(Box)(({ theme }: any) => ({
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1.5rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }))

  export const SubmitButtonContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }))

  export const BlockchainDropdownContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5rem',
    [theme.breakpoints.down('md')]: {
      gap: '7rem',
    },
  }))

  export const DepositContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '2rem',
    justifyContent: 'space-between',
    paddingTop: '2rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginLeft: 'unset',
      flexWrap: 'wrap',
    },
  }))

  export const QRContainer = styled(Box)(({ theme, medium1 }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.75rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gap: '1rem',
      alignItems: 'center',
      display: medium1 ? 'flex' : 'none',
    },
  }))

  export const QRWrapper = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }))

  export const CreatePurcahseRightContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5.5rem',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      gap: '1.5rem',
      alignItems: 'center',
    },
  }))

  export const UsdcContiner = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    height: '116px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  }))

  export const TransactionHashContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    paddingTop: '3rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      paddingTop: 'unset',
      gap: '2rem',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '9rem',
    },
  }))

  export const CreditPurchaseDivider = styled(Divider)(({ theme }: any) => ({
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#0B213E',
    padding: '2rem 0',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '3rem 0',
    },
  }))

  export const CustomStepper = styled(Stepper)(({ theme, displaytoggle, activestepstyle }: any) => ({
    height: 'inherit',
    padding: '0 2.5rem',
    gap: displaytoggle === 'true' ? '2rem' : 'unset',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 2rem',
      alignItems: 'end',
      order: 1,
      gap: displaytoggle === 'true' ? '0' : 'unset',
      width: activestepstyle === 'true' ? '100%' : 'none',
      borderBottom: '1px solid #DEE7FD',
    },
  }))

  export const ActivateModalStepper = styled(Stepper)(({ theme }: any) => ({
    height: 'inherit',
    justifyContent: 'space-around',
    width: '100%',
    marginRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
    },
  }))

  export const ConfirmDetailsContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
    [theme.breakpoints.down('md')]: {
      gap: '1rem',
    },
  }))

  export const LinkContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2.5rem',
    [theme.breakpoints.down('md')]: {
      gap: '1.5rem',
    },
  }))

  export const CreatePurcahseMainContainer = styled(Box)(({ theme }: any) => ({
    [theme.breakpoints.down(396)]: {
      overflowX: 'hidden',
      height: '38.6rem',
      overflowY: 'auto',
    },
    [theme.breakpoints.down(391)]: {
      overflowX: 'hidden',
      height: '35.6rem',
      overflowY: 'auto',
    },
    [theme.breakpoints.down(376)]: {
      overflowX: 'hidden',
      height: '24.6rem',
      overflowY: 'auto',
    },
    [theme.breakpoints.down(361)]: {
      overflowX: 'hidden',
      height: '28.6rem',
      overflowY: 'auto',
    },
  }))
  export const CreditPaymentContainer = styled(Box)(({ theme, showEmailField }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: showEmailField ? '1rem' : '6rem',
    padding: '1rem 2rem',
    [theme.breakpoints.down('md')]: {
      // minHeight: '59vh',
      // height: 'calc(70vh - 45px)',
      // overflowY: 'auto',
      // aspectRatio: 'auto',
      justifyContent: 'space-between',
      // height: '28rem',
    },
    [theme.breakpoints.down(378)]: {
      height: 'calc(54vh - 200px)',
      overflowY: 'auto',
      minHeight: '54vh',
    },
  }))

  export const CreditCardBdy = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }))

  export const CreditCardBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.5rem',
    flexWrap: 'wrap',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  }))
  export const PaymentIconContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '3rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
    },
  }))
  export const PaymentIcon = styled.img(({ theme }: any) => ({
    height:'38px',
    
  }))
  export const paymentIcon = styled(Box)(({ theme, medium }: any) => ({
    display: 'flex',
    gap: medium ? '1rem' : '3rem',
    flexWrap: 'wrap',
  }))

  export const EmailTextContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    // gap: '0.9rem',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  }))

  export const CreditCardFooter = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    // alignItems: 'center',
    gap: '0.5rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  }))

  export const RewardContents = styled(Box)(({ theme, style }: any) => ({
    marginTop:"2rem",
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [theme.breakpoints.down('md')]: {},
  }))

  export const CreditCardPaymentText = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down(500)]: {
      textAlign: 'center',
    },
  }))

  export const EarnText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
  }))

  export const PayText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.125rem',
  }))

  export const GasFeeText = styled(Typography)(({ theme }: any) => ({
    color: '#66717B',
    fontSize: '0.625rem',
  }))

  export const PaymentMessage = styled(Typography)(({ theme }: any) => ({
    color: '#66717B',
    minHeight: '1.5rem',
  }))

  export const DepositAddressContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'center',
  }))

  export const ButtonContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: 'unset',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }))

  export const StripeContainer = styled(Box)(({ theme }: any) => ({
    color: '#414141',
    alignItems: 'center',
    gap: '0.4rem',
  }))

  export const CompanyName = styled(Typography)(({ theme }: any) => ({
    color: '#0B213E',
    fontSize: '1.5rem',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      width: '8rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'auto',
    },
  }))

  export const EmailInput = styled.input(({ theme }: any) => ({
    background: '#ced6e040',
    color: '#66717B',
    borderRadius: '0.5rem',
    padding: '1rem 0.75rem',
    fontWeight: 400,
    fontSize: '1.125rem !important',
    lineHeight: '1.4375rem !important',
    outline: 'none',
    border: 'none',
    '&::placeholder': {
      '&::after': {
        content: '"*"',
        color: 'red',
      },
    },
  }))

  export const HelperText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.grey[600],
    fontSize: '0.9375rem',
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      paddingTop: '1rem',
    },
  }))

  export const SelectorsWrapper = styled(Box)(({ theme, displaytoggle, activatedBuyStyl, selectedOfferType }: any) => ({
    borderBottom: '1px solid #DEE7FD',
    minHeight: '3rem',
    padding: '1rem 0',
    display: displaytoggle === 'true' ? 'flex' : 'grid',
    gridTemplateColumns: selectedOfferType ? '2fr 1fr' : '1fr 1fr',
    justifyContent: displaytoggle === 'true' ? 'flex-end' : 'unset',
    margin: '0 1.5rem 0 2rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'unset',
      gridTemplateRows: '1fr',
      borderBottom: 'none',
      margin: '0 !important',
      padding: '0',
      // display: activatedBuyStyle ? 'none' :'grid'
    },
  }))

  export const ContrastProgress = styled(CircularProgress)(({ theme }: any) => ({
    color: theme.palette.primary.contrastText,
  }))
  export const FilterContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#f3f5f8',
    padding: '1rem 4rem 1rem 2rem',
  }))
}
