import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'

export namespace S {
  export const TopupCardContainer = styled(Box)(({ theme }: any) => ({
    minHeight: '16.75rem',
    minWidth: '24.1875rem',
    width: '24.1875rem',
    background: theme.palette.primary.contrastText,
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1);',
    borderRadius: '0.5rem',
    display: 'grid',
    gridTemplateRows: '74.77px auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      minWidth: '0',
    },
  }))
  export const BestDealChip = styled(Box)(({ theme }: any) => ({
    width: '5.6875rem',
    height: '1.4375rem',
    background: '#EF5153',
    borderRadius: '8px 8px 0 0',
    position: 'absolute',
    display: 'grid',
    placeContent: 'center',
    top: '-1.4375rem',
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: '0.8125rem',
  }))
  export const TopupCardHeader = styled(Box)(({ theme, gradientstart, gradientend }: any) => ({
    width: '100%',
    background: `linear-gradient(113.74deg, ${gradientstart} 9.6%, ${gradientend} 91.34%)`,
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1.25rem',
    boxSizing: 'border-box',
  }))
  export const TopupCardBody = styled(Box)(({ theme }: any) => ({
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3.5rem',
    '@media(max-width:480px)': {
      padding: '1rem',
    },
  }))
  export const ProviderDetailsSection = styled(Box)(({ theme }: any) => ({
    borderBottom: '0.5px solid rgba(102, 113, 123, 0.5)',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    boxSizing: 'border-box',
    padding: '0 0 1.25rem 0',
  }))
  export const TopupCardSubBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    alignItems: 'flex-start',
  }))
  export const PaymentSubBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }))
  export const TopupCardFooter = styled(Box)(({ theme }: any) => ({
    width: '100%',
    position: 'absolute',
    display: 'flex',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'space-evenly',
    borderRadius: '8px',
    bottom: 0,
    padding: '0rem 0',
  }))
  export const BuyButton = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '3.125rem',
    width: '10rem',
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))

  export const TopupButton = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '3.625rem',
    width: '100%',
    borderRadius: '0px 0px 8px 8px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))
  export const GiftButton = styled(BuyButton)(({ theme }: any) => ({
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    height: '3.125rem',
    width: '10rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.secondary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))
  export const PaymentSection = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    boxSizing: 'border-box',
    padding: '0.5rem 0 0 0',
  }))
  export const HeaderDataContainer = styled(Box)(({ theme }: any) => ({
    fontWeight: '500',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'baseline',
  }))
  export const ProviderWrapper = styled(Box)(({ theme }: any) => ({}))
  export const ProviderContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
  }))
  export const RewardsContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  }))
  export const DataValueText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.75rem',
  }))
  export const CountryText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.75rem',
  }))
  export const SubBodyText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '0.875rem',
  }))
  export const SubBodyOverFlowText = styled(Typography)(({ theme }: any) => ({
    whiteSpace: 'nowrap',
    width: '83px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  }))

  export const PaymentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
  }))
  export const PriceText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '500',
    fontSize: '1.75rem',
  }))
  export const ProviderValueText = styled(SubBodyText)(({ theme }: any) => ({
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  }))
  export const PercentageText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }))
  export const CoverageIconImg = styled.img(({ theme }: any) => ({
    cursor: 'pointer',
  }))
}
