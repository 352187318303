import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace resaleClient {
  export const getResalePackDetails = async (token: any, data: any, chain_id: number, chain_name: string) => {
    const response = await httpClient.post(
      'resale-offers/checkResalePackPrice',
      { purchaseId: data,
        chain_id: chain_id,
        chain_name: chain_name
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const createResalePack = async (token: any, data: any) => {
    const response = await httpClient.post(
      'resale-offers/create',
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const getResalePacks = async (token: any) => {
    const response = await httpClient.get('resale-offers/findActiveResaleOffers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }
  
  export const getResalePackDetailsById = async (token: any, purchaseId: any) => {
    const response = await httpClient.get(`resale-offers/${purchaseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const delistResalePack = async (token: any, data: any) => {
    const response = await httpClient.post(
      'resale-offers/deListResaleOffer/web',
      { purchaseId: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const checkResaleAllowed = async (token: any, purchaseId: any) => {
    const response = await httpClient.get(`resale-offers/isResaleAllowed/${purchaseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }
}
