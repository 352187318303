import React, { FC, useEffect, useRef } from 'react'
import Select, { components, ControlProps, OptionProps } from 'react-select'
import DownArrow from 'assets/icons/DownArrow.svg'
import WorldIcon from 'assets/icons/World.svg'
import { useAppContext } from 'contexts/app.context'
import { countriesWithPlaceholderFlag } from 'utils/constants'
import { userClient } from 'services/http/user.service'
import { useWalletContext } from 'contexts/wallet.context'
import { useSearchParams } from 'react-router-dom'

interface Props {
  selectedValue: any
  setSelectedValue: any
  options: any
  placeholder?: string
  type?: string
  showIcon?: Boolean
}

const CustomDropDown: FC<Props> = ({ type, placeholder, selectedValue, setSelectedValue, options, showIcon }) => {
  const { selectedCountry, setSelectedProvider, setSelectedDataValue, selectedProvider } = useAppContext()
  const { userLogin } = useWalletContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectRef:any = useRef()
  const disableDropDown =
    (type === 'datalimit' || type === 'provider') && (selectedCountry?.value === '' || selectedCountry === null)

  const keyDownHandler = (event: KeyboardEvent) => {    
      if ((event.ctrlKey || event.metaKey )&& event.key === "k") {
        event.preventDefault()
        selectRef.current?.focus()
      }
    }
  
  useEffect(() => {
      window.addEventListener("keydown", keyDownHandler);
  })
    
  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <>
        <components.Control {...props}>
          {/*country filter  */}
          {type &&
            (type === 'country' || type === 'network') &&
            (selectedCountry === null ||
            selectedCountry?.value === '' ||
            selectedCountry?.value === 'Global' ||
            showIcon === false ? (
              <></>
            ) : (
              <>
                {type !== 'network' && countriesWithPlaceholderFlag.includes(selectedValue?.flagCountryCode) ? (
                  <img src={WorldIcon} alt="currency img" style={{ width: '26px', marginRight: '0.9375rem' }} />
                ) : (
                  <img
                    alt="flag"
                    style={{ height: '1.5rem', width: '1.5rem' }}
                    src={selectedValue?.flagCountryCode === 'Africa' || selectedValue?.flagCountryCode ==='Caribbean Islands'?WorldIcon:`https://giant-protocol.github.io/country-flag-icons/3x2/${selectedValue?.flagCountryCode?.toUpperCase()}.svg`}
                  />
                )}
              </>
            ))}
          {/*provider filter  */}
          {type &&
            type === 'provider' &&
            (selectedValue === null || selectedValue?.label === '' || selectedValue?.logo === ''? (
              <></>
            ) : (
              <img
                src={`data:image/png;base64,${selectedValue?.logo}`}
                alt="provider logo"
                style={{ width: '28px', height: '28px' }}
              />
            ))}
          {type &&
            type === 'network' &&
            (selectedValue === null || selectedValue?.label === '' ? (
              <></>
            ) : (
              <img
                alt="flag"
                style={{ height: '1.5rem', width: '1.5rem' }}
                src={selectedValue?.flagCountryCode === 'Africa' || selectedValue?.flagCountryCode ==='Caribbean Islands'?WorldIcon:`https://giant-protocol.github.io/country-flag-icons/3x2/${selectedValue?.flagCountryCode.toUpperCase()}.svg`}
              />
            ))}
          {children} <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    )
  }

  const IconOption = (props: any) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {type !== 'status' && type !== 'datalimit' && type !== 'provider' && (
          <>
            {countriesWithPlaceholderFlag.includes(props?.data?.flagCountryCode) ? (
              <img src={WorldIcon} alt="currency img" style={{ width: '23px', marginRight: '0.9375rem' }} />
            ) : (
              <img
                alt="country flag"
                style={{ height: '1.5rem', width: '1.5rem', marginRight: '1rem' }}
                src={props?.data?.flagCountryCode === 'Africa' || props?.data?.flagCountryCode ==='Caribbean Islands'?WorldIcon:`https://giant-protocol.github.io/country-flag-icons/3x2/${props?.data?.flagCountryCode?.toUpperCase()}.svg`}
              />
            )}
          </>
        )}

        {type !== 'status' && type === 'provider' && (
          <img
            src={`data:image/png;base64,${props?.data?.logo}`}
            alt="logo"
            style={{ width: '1.75rem', height: '1.75rem', marginRight: '1rem' }}
          />
        )}

        {props.data.label}
      </div>
    </components.Option>
  )

  const handleSegmentEvent = (e: any) => {
    if (type === 'country' && e !== null) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'selected Country',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': 'Shop',
            country: e?.value,
          },
        },
        userLogin,
      )
    }

    if (type === 'provider' && e !== null) {
      userClient.postUserEventsWithAuth(
        {
          eventName: 'selected Provider',
          properties: {
            build: process.env.REACT_APP_CURRENT_CHAIN,
            'screen name': 'Shop',
            country: selectedCountry?.value,
            provider: e?.value,
          },
        },
        userLogin,
      )
    }
  }

  return (
    <>
      <Select
        options={options}
        value={selectedValue?.value && selectedValue}
        ref={selectRef}
        openMenuOnFocus={true}
        onChange={(e) => {
          handleSegmentEvent(e)
          // console.log(e)
          if (type === 'country' && e === null) {
            if (searchParams.has('region')) {
              searchParams.delete('region')
              setSearchParams(searchParams)
            }
            if (searchParams.has('datalimitingb')) {
              searchParams.delete('datalimitingb')
              setSearchParams(searchParams)
            }
            if (searchParams.has('provider')) {
              searchParams.delete('provider')
              setSearchParams(searchParams)
            }
            setSelectedProvider({ value: '', label: '', logo: '' })
            setSelectedDataValue({ value: '', label: '' })
          }

          setSelectedValue(e)
        }}
        isSearchable
        components={{ Control, Option: IconOption }}
        placeholder={placeholder}
        //isClearable
        // closeMenuOnSelect={false}
        isDisabled={disableDropDown}
        styles={{
          placeholder: (base:any) => ({
            ...base,
            opacity: '0.8',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: '1.9375rem',
            color: disableDropDown ? '#6F6F73' : '#000000',
            '@media(max-width:600px)': {
              fontSize: '1rem',
            },
          }),
          control: (base:any) => ({
            ...base,
            display: 'flex',
            gap: '0.5rem !important',
            background: '#e2e7ee',
            border: 'none',
            outline: 'none',
            borderRadius: '0.25rem',
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: '1.9375rem',
            color: '#000000',
            cursor: 'pointer',
            padding: '0.2rem 2rem',
            width: type === 'network' ? '100%' : '24.1875rem',
            height: '3.25rem',
            '@media(max-width:600px)': {
              fontSize: '1rem',
              width: '24.1875rem',
              height: '42px',
              padding: '0 1rem',
            },
          }),
          indicatorSeparator: (base:any) => ({
            ...base,
            display: 'none',
          }),
          // clearIndicator: (base) => ({
          //   ...base,
          //   cursor: 'pointer',
          //   color: 'black',
          // }),
          dropdownIndicator: (base:any) => ({
            ...base,
            display: 'none',
          }),
          container: (base:any) => ({
            ...base,
            maxWidth:
              type === 'country'
                ? '23.5rem'
                : type === 'provider'
                ? '23.5rem'
                : type === 'network'
                ? '40rem'
                : '21.5rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media(max-width:600px)': {
              maxWidth: type === 'provider' ? '14.9375rem' : '24.5rem',
            },
            // padding: '0 0.5rem',
          }),
          menu: (base:any) => ({
            ...base,
            maxWidth: type === 'network' ? '100%' : '24.1875rem',
            zIndex: '99',
            '@media(max-width:600px)': {
              maxWidth: '20rem',
            },
          }),
          menuList: (base:any) => ({
            ...base,
            '::-webkit-scrollbar': {
              width: '6px',
              height: '0px',
            },
            '::-webkit-scrollbar-track': {
              background: '#DEE7FD',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#45B549',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#45B549',
            },
          }),
        }}
      />
    </>
  )
}

export default CustomDropDown
