import { HttpClient } from 'utils/constants/httpClient'

let httpClient = new HttpClient()

export namespace stakingClient {
  export const getStakingHistory = async (token: any, type: string, page: number) => {
    const response = await httpClient.get(
      `total-rewards/graph/stakingHistory?page=${page}&pageSize=10&type=${type === 'stake' ? 'Staked' : 'Unstaked'}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const getEarningRewardsHistory = async (token: any, page: number) => {
    const response = await httpClient.get(`total-rewards/earnRewardsHistory?page=${page}&pageSize=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }

  export const postUnstakeQues = async (token: any, data: any) => {
    const response = await httpClient.post(
      `unstake-queues`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const getUnstakeRequestQues = async (token: any, page: number) => {
    const response = await httpClient.post(
      `unstake-queues/findByPage`,
      {
        limit: 10,
        page: page,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  }

  export const cencelUnstakeRequest = async (id: any, token: any) => {
    const response = await httpClient.delete(`unstake-queues/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  }
  export const stakeNFT = async (token: any, data: any) => {
    const response = await httpClient.post(
      `stakeNFT`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Chain-Type':'testnet',
          'X-Chain-Id':84532
        },
      },
    )
    return response
  }
}
