import { paymentClient } from 'services/http/payment.service'
import { userClient } from 'services/http/user.service'
import { createBulkPayment, createPayment, linkEmail,createCryptoPayment } from 'utils/constants/config'
import { toast } from 'react-toastify'

export const createCreditPayment = async (data: any, creditStatus: boolean) => {
  const {
    navigate,
    userLogin,
    segmentData,
    userDetails,
    pathname,
    paymentType,
    modalData,
    purchaseType,
    selectedOfferType,
    isTopup,
    purchaseId,
    setIsPaymentLoading,
    userEmail,
    setCheckoutUrl,
    setCheckoutSuccess,
    setDisableModal
  } = data
  navigate('/shop')
  if (userLogin) {
    userClient.postUserEventsWithAuth(
      {
        eventName: 'Clicked Next on Credit Card payment',
        properties: {
          build: process.env.REACT_APP_CURRENT_CHAIN,
          offer: segmentData,
          'payment method': 'Credit Card',
          'screen name': `${pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
          email: userDetails?.user?.email ? userDetails?.user?.email : userEmail,
        },
      },
      userLogin,
    )
  }

  if (!userDetails?.user?.email) {
    await linkEmail({ email: userEmail }, userLogin)
  }

  if(modalData?.quantity <= 1) {
      let  variables = {
        data: {
          referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
          paymentType: creditStatus ? 'credits' : paymentType,
          offerId: modalData?.id,
          purchaseType: purchaseType,
          isTopup: isTopup,
          purchaseId:purchaseId,
          isResalePack: selectedOfferType,
          chain: 'stripe',
          transactionHash: `stripe-${userDetails?.user?.email ? userDetails?.user?.email : userEmail}-${
            modalData?.id
          }-${Date.now()}`,
        },
      }
      setIsPaymentLoading(true)    
      createPayment(variables.data, userLogin)
        .then((res) => {
          /* console.log(
            `# createPayment creditStatus: ${creditStatus}, res.status: ${res.status}, resObj: ${JSON.stringify(res)}`,
          )*/
          if (res.status === 'success') {
            if (creditStatus) {
              window.open(`/payment/${res.id}?status=success`, '_self')
              setCheckoutSuccess(true)
            } else {
              paymentClient
                .createCheckout(res.id, userDetails?.user?.email ? userDetails?.user?.email : userEmail, userLogin)
                .then((res: any) => {
                  if (res.status === 200) {
                    setCheckoutUrl(res.url)
                    setCheckoutSuccess(true)
                  }
                })
                .catch((e) => {                  
                  setIsPaymentLoading(false)
                  toast.error(e?.response?.data?.message?e?.response?.data?.message:'Error.Please try again')
                })
            }
          }
        })
        .catch((e) => {
          setIsPaymentLoading(false)
          toast.error(e?.response?.data?.message?e?.response?.data?.message:'Error.Please try again')
        })
    }else{
      let variables = {
        data: {
          paymentType:  creditStatus ? 'credits' : paymentType,
          offerId: modalData?.id,
          purchaseType: purchaseType,
          isResalePack:selectedOfferType?true:false,
          chain: "credits",
          transactionHash: `credits-${userDetails?.user?.email ? userDetails?.user?.email : userEmail}-${modalData?.id}-${Date.now()}`,
          referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
          purchaseQuantity:modalData?.quantity,
          platform:'web'
        },
      }
      createBulkPayment(variables.data, userLogin)
      .then((res) => {        
        if (res.status === 'success') {
          if (creditStatus) {
            //window.open(`/payment/${res.id}?bulkstatus=success`, '_self')
            setCheckoutSuccess(true)
            navigate(`/payment/${res.id}?status=success`, '_self');

          } else {
            paymentClient
              .createCheckout(res.id, userDetails?.user?.email ? userDetails?.user?.email : userEmail, userLogin)
              .then((res: any) => {
                if (res.status === 200) {
                  setCheckoutUrl(res.url)
                  setCheckoutSuccess(true)
                }
              })
              .catch((e) => {
                // console.log('Payment checkout failed', e)
                setIsPaymentLoading(false)
              })
          }
        }
      })
      .catch((e) => {
        setIsPaymentLoading(false)
      })
    }

  // if (creditStatus) {
  //   createPayment(variables.data, userLogin)
  //     .then((res) => {
  //       console.log(res, 'credit true response')
  //       if (res.status === 200) {
  //         window.open(`${window.location.hostname}/payment/${res.id}?status=success`, '_self')
  //         setCheckoutSuccess(true)
  //       }
  //     })
  //     .catch((e) => {
  //       setIsPaymentLoading(false)
  //       // Todo: show failure screen and try again
  //     })
  // } else {
  //   createPayment(variables.data, userLogin)
  //     .then((res) => {
  //       paymentClient.createCheckout(res.id, userDetails?.user?.email ? userDetails?.user?.email : userEmail, userLogin)
  //         .then((res: any) => {
  //           if (res.status === 200) {
  //             setCheckoutUrl(res.url)
  //             setCheckoutSuccess(true)
  //           }
  //         })
  //         .catch((e) => {
  //           setIsPaymentLoading(false)
  //         })
  //     })
  //     .catch((e) => {
  //       setIsPaymentLoading(false)
  //       console.log('Error while making credit card payment--->', e)
  //     })
  // }
}


export const getPaymentPrice = (price: any, credit: any) => {
  let packPrice = price
  let creditsValue = parseFloat(credit)
  let calculatedValue = packPrice - creditsValue

  if (calculatedValue <= 0) {
    return '0.00'
  } else if (calculatedValue < 1) {
    return '1.00'
  } else {
    return calculatedValue.toFixed(2)
  }
}
export const getCryptoPaymentPrice = (price: any, credit: any) => {
  let packPrice = price
  let creditsValue = parseFloat(credit)
  let calculatedValue = packPrice - creditsValue

  if (calculatedValue <= 0) {
    return '0.00'
  } else {
    return calculatedValue.toFixed(2)
  }
}
export const cryptoPayment = async (data: any ) => {
  const {
    userLogin,
    segmentData,
    userDetails,
    pathname,
    paymentType,
    modalData,
    purchaseType,
    selectedOfferType,
    isTopup,
    purchaseId,
    setIsPaymentLoading,
    userEmail,
    setCheckoutUrl,
    setCheckoutSuccess,
  } = data

  if (userLogin) {
    userClient.postUserEventsWithAuth(
      {
        eventName: 'Clicked Next on '+ paymentType ==='coinbase'?'Coinbase':paymentType === 'cto'?'Crypto.com':paymentType +' payment',
        properties: {
          build: process.env.REACT_APP_CURRENT_CHAIN,
          offer: segmentData,
          'payment method': 'Crypto',
          'screen name': `${pathname === '/' ? 'Home' : selectedOfferType ? 'Shop/Resale' : 'Shop'}`,
          email: userDetails?.user?.email ? userDetails?.user?.email : userEmail,
        },
      },
      userLogin,
    )
  }

  const variables = {
    data: {      
      paymentType: 'crypto',
      offerId: modalData?.id,
      purchaseType: purchaseType,      
      chain: paymentType ==='coinbase'?'coinbase':paymentType === 'cto'?'CTO':paymentType,
      referrer: pathname.split('/').includes('referral') ? pathname.split('/').slice(-1)[0] : null,
      platform: 'web', 
      isResalePack: selectedOfferType,     
      isTopup: isTopup,
      purchaseQuantity:modalData?.quantity,
      purchaseId:purchaseId,      
    },
  }
  setIsPaymentLoading(true)

  if (!userDetails?.user?.email) {
    await linkEmail({ email: userEmail }, userLogin)
  }

  createCryptoPayment(variables.data, userLogin)
    .then((res:any) => {  
      if (res.status === 'success') {
        setCheckoutUrl(res.url)
        setCheckoutSuccess(true)      
      }
    }).catch((e) => {
      setIsPaymentLoading(false)
      toast.error(e?.response?.data?.message?e?.response?.data?.message:'Bad request. Please refresh and try again')
    })
    .catch((e) => {
      setIsPaymentLoading(false)
      toast.error(e?.response?.data?.message?e?.response?.data?.message:'Error.Please try again')
    })
}