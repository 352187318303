import axios from 'axios'
import { HttpClient } from './httpClient'

let httpClient = new HttpClient()

export const headers = {
  'content-type': 'application/json',
}

export const checkDCTStatus = ({ id, poolId, token }) => {
  if (!poolId) {
    return httpClient.get(`purchases/checkDCTStatus/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    return httpClient.get(`purchases/checkDCTStatus/${id}?poolId=${poolId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

export const dctTransfer = (data, token) => {
  return httpClient.post(
    'transferDct',
    { data },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export const dctExpire = (id) => {
  return httpClient.post(`instanceExpire?instanceId=${id}`)
}

export const getDefaultDctSales = async (token) => {
  return await httpClient.get(`dct-sales`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getFaucetStatus = (address, token) => {
  return httpClient.get(`faucetStatus?address=${address}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const fetchQueries = async (graphqlQuery, token) => {
  let headerData
  if (token) {
    headerData = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } else {
    headerData = {
      'content-type': 'application/json',
    }
  }
  return await axios({
    url: `${process.env.REACT_APP_BACKEND_URL}graphql`,
    method: 'POST',
    headers: headerData,
    data: JSON.stringify(graphqlQuery),
  })
}

export const fetchHarvester = async (graphqlQuery, token) => {
  let headerData
  if (token) {
    headerData = {
      'content-type': 'application/json',
    }
  } else {
    headerData = {
      'content-type': 'application/json',
    }
  }
  return await axios({
    url: `${process.env.REACT_APP_HARVESTER_URL}graphql`,
    method: 'POST',
    headers: headerData,
    data: JSON.stringify(graphqlQuery),
  })
}

export const activatePurchase = async (purchaseId, token) => {
  return await httpClient.get(`activatePurchase/${purchaseId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const activateProvider = async (purchaseId, token) => {
  return await httpClient.get(`activateProvider/${purchaseId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const providerList = async (params, token) => {
  return await httpClient.get(`stakersListByPoolId/${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const activateDct = async (code, token) => {
  return await httpClient.get(`v2/activateDct/${code}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const activateDctV3 = async (params, token, chainId) => {
  let headers = chainId ? {Authorization: `Bearer ${token}`,'X-Chain-Id' : chainId } : {Authorization: `Bearer ${token}` }
  return await httpClient.post('purchases/v3/activateDct', params, {
    headers: headers ,
  })
}

export const totalStaked = async (token) => {
  return await httpClient.get(`totalStaked`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const totalReward = async (token) => {
  return await httpClient.get(`v2/total-rewards`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const dctSales = async () => {
  return await httpClient.get('dctSales')
}

export const dctSalesById = async (id, token) => {
  return await httpClient.get(`purchases/getDctSales/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getDestination = async (token, chain) => {
  return await httpClient.get(`payments/getDestination/${chain}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getTransactionStatus = async (id, tx, token) => {
  return await httpClient.get(`transfers/${id}/${tx}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const linkEmail = async (params, token) => {
  return await httpClient.post('accounts/linkEmail', params, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const LeaderboardChallenges = async () => {
  return await httpClient.get(`challenges`)
}

export const getPurchasesData = async (token) => {
  return await httpClient.get('purchases', {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getConsumptionStatus = async (purchaseId, token) => {
  return await httpClient.get(`consumptionStatus/${purchaseId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const createPayment = async (params, token) => {
  return await httpClient.post('/payments/create', params, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const createCryptoPayment = async (params, token) => {
  console.log(params)
  return await httpClient.post('/v2/payment/crypto/createCharge', params, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
export const createBulkPayment = async (params, token) => {
  return await httpClient.post('/v2/payment/createBulkPurchase', params, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const createCreditsPayment = async (params, token) => {
  return await httpClient.post('/v2/payment/buyPacksUsingCredits', params, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
