import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'

export namespace S {
  export const ModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    width: '785px',
    maxHeight: 'calc(100vh - 1rem)',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '99',
    borderRadius: '5px',
    outline: 'none',
    '@media (min-width: 900px)': {
      overflowY: 'auto',
    },
    '@media (max-width: 900px)': {
      width: '80%',
      overflowX: 'hidden',
    },
    '@media (max-width: 600px)': {
      width: '95%',
    },
  }))
  export const ModalHeader = styled(Box)(({ theme, color }: any) => ({
    minHeight: '84px',
    background: `linear-gradient(113.74deg, ${color.gradientstart ?? '#CCE3FF'} 9.6%,  ${
      color.gradientend ?? '#EFF6FF'
    } 91.34%)`,
    // opacity: 0.2,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem 0 2rem',
    borderRadius: '8px 8px 0px 0px',
    gap: '0.5rem',
    flexWrap: 'wrap',
    position: 'sticky',
    top: '0',
    zIndex: '4',
  }))
  export const ModalHeaderLeft = styled(Box)(({ theme }: any) => ({}))

  export const DataPlanTypeText = styled(Typography)(({ theme }: any) => ({
    fontSize: '46px',
    fontWeight: '700',
  }))

  export const DataLimitContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  export const TopContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0',
  }))

  export const BottomContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0',
  }))

  export const DistributionContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0',
  }))

  export const DistributionHeader = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '16px',
    color: '#999999',
  }))
  export const DistributionSubContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '55%',
  }))

  export const DistributionText = styled(DistributionHeader)(({ theme }: any) => ({
    color: '#414141',
  }))

  export const InfoText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '0.8125rem',
    color: '#414141',
  }))

  export const DataValue = styled.div(({ theme }: any) => ({
    display: 'flex',
    gap: '0.3rem',
  }))

  export const DataSize = styled(Typography)(({ theme, currentdata }: any) => ({
    fontWeight: '700',
    fontSize: '3.25rem',
    color: '#131A22',
  }))

  export const DataLimit = styled(Typography)(({ theme, currentdata }: any) => ({
    fontWeight: '700',
    fontSize: '2.25rem',
    paddingTop: '1rem',
    color: '#131A22',
  }))

  export const SoldBtn = styled(Button)(({ theme, currentdata }: any) => ({
    color: theme.palette.common.white,
    background: '#C5C5C5',
    height: '44px',
    width: '265px',
    borderRadius: '8px',
    marginTop: '1.5rem',
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
  }))

  export const DataValidity = styled(Typography)(({ theme, currentdata }: any) => ({
    paddingTop: '1rem',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#0B213E',
  }))

  export const ModalHeaderRight = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }))

  export const ModalHeaderRightTitle = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
  }))

  export const HeadCountryTitle = styled(Typography)(({ theme }: any) => ({
    fontSize: '32px',
    fontWeight: '700',
  }))

  export const StatusContainer = styled(Box)(({ theme }: any) => ({
    fontSize: '20px',
    fontWeight: '700',
    padding: '0 0 18px 0',
  }))

  export const GrayContainer = styled(Box)(({ theme }: any) => ({
    height: '94px',
    background: '#F3F5F7',
    border: '1px solid #DEE7FD',
    borderRadius: '8px',
    padding: '0.75rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.85rem',
  }))

  export const InnerContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }))

  export const SoldGreenText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.primary.main,
  }))

  export const ProviderText = styled(Box)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  }))

  export const ExpiryText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '18px',
    color: '#66717B',
  }))

  export const RewardsContainer = styled(Typography)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '18px',
    color: '#66717B',
  }))

  export const ModalBodyContainer = styled(Box)(({ theme }: any) => ({
    padding: '0.5rem 2rem 2rem 2rem',
    height: '85%',
    '@media (max-width: 900px)': {
      overflowY: 'auto',
      padding: '0.5rem 2rem',
    },
    '@media (max-width: 600px)': {
      gap: '1rem',
    },
  }))
}
