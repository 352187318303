import React, { useEffect } from 'react'
import { S } from './styled'
import ModalRightArrow from 'assets/icons/modal-right-arrow.svg'
import { useAccount, useConnect, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi'
import { useWalletContext } from 'contexts/wallet.context'
import metaMask from 'assets/metamask.png';
import walletconnect from 'assets/walletconnect.png';
import BaseLogoBlue from 'assets/icons/BaseLogoBlue.svg'
import CoinbaseWalletLogo from 'assets/icons/CoinbaseWalletLogo.png'
import { toast } from 'react-toastify'
import { useAppContext } from 'contexts/app.context'

const WagmiConnect = () => {
 const { setRefetchMydata } = useAppContext()
 const { address, connector, isConnected } = useAccount()
 const { data: ensName } = useEnsName({ address })
 const { data: ensAvatar } = useEnsAvatar({ name: ensName })
 const chainID = Number(process.env.REACT_APP_CHAIN_ID ?? '8453')
 const { connect, connectors, error, isLoading, pendingConnector } = useConnect({
   onError() {
     setWagmiConnecting(false)
   },
   onSuccess() {
    setTimeout(() => { setRefetchMydata(true) }, 2000)
    setWagmiConnecting(false)
   }       
 })
 const { disconnect } = useDisconnect()
 const { setWagmiConnecting, setWagmiConnected, setConnectedModalOpen, isWagmiConnected } = useWalletContext()
 
 const handleConnect = (connector: any) => { connect({ connector,chainId:chainID }) }


 useEffect(() => {
   setWagmiConnecting(isLoading)   
 }, [isLoading])

  useEffect(() => {
   setWagmiConnected(isConnected) 
   isConnected&&setTimeout(() => { setConnectedModalOpen(false) }, 250) 
 }, [isConnected])
 
 useEffect(() => {
  toast.error(error?.message)
 }, [error])

 const showEVMWallets = (process.env.REACT_APP_ENABLE_BASE_CHAIN === 'true');
 return (
   <>{showEVMWallets?<>
   {connectors.map((connector) => (
     <S.WagmiChainBox key={connector?.id}>
     <S.WagmiConnectButton
       disabled={!connector.ready}
       key={connector.id}
       onClick={() => handleConnect(connector)}
     >
       <img src={connector.name === 'MetaMask'?metaMask:connector.name === 'WalletConnect'?walletconnect:connector.name === 'Coinbase Wallet'?CoinbaseWalletLogo:BaseLogoBlue} alt="connectorLogo" style={{marginRight:'10px',width: '24px'}} />
       {connector.name}
       {!connector.ready && ' (unsupported)'}
       {isLoading &&
         connector.id === pendingConnector?.id &&
         ' (connecting)'}
         <img src={ModalRightArrow} alt="walletLogo" style={{marginLeft:'auto'}}/>
     </S.WagmiConnectButton>
     </S.WagmiChainBox>
   ))}
   {/*error && <div>{error.message}</div>*/}
   </>:
   <S.MessageBox>
       <S.animateCharacter >COMING SOON!</S.animateCharacter>       
   </S.MessageBox>
   }
    
 </>
 )
}


export default WagmiConnect
