import { FC, useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import CheckedIcon from 'assets/icons/Checked.svg'
import UnCheckedIcon from 'assets/icons/UnChecked.svg'
import { GIFT, PURCHASE_TYPE_BUY, SCAN } from 'utils/constants'
import { usePaymentContext } from 'contexts/payment.context'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'
import ActivationFailure from './ActivationFailure'

import { B } from './styled'
import { usePaymentConfirmation } from 'hooks/paymentConfirmation.hook'
import { purchaseclient } from 'services/http/purchases.service'
import { useAppContext } from 'contexts/app.context'

interface Props {
  retryStatus: boolean
  handleClose: any
}

const CheckPurchase: FC<Props> = ({ retryStatus, handleClose }) => {
  const { paymentId, walletPurchaseData, offerData } = usePaymentContext()
  const { purchaseType, userLogin, setShowPurchaseModal, userDetails } = useWalletContext()
  const { setActiveStep, setActivateNow, setReceiverState, setpostPurchaseSurvey, openActivationFailureModal } = useModalContext()
  const {
    isPaymentSuccess,
    isFinalized,
    isPaymentFailed,
    mintStatus,
    mintRetryCount,
    providerPurchaseStatus,
    purchaseRetryCount,
    paymentError,
  } = usePaymentConfirmation({ paymentId, handleClose })
  const { selectedOfferType } = useAppContext()
  const { pathname } = useLocation()

  useEffect(() => {
    if (isFinalized === true && isPaymentFailed === false) {
      if (pathname.split('/').includes('payment')) {
        if (selectedOfferType) {
          if (purchaseType === PURCHASE_TYPE_BUY) {
            setActivateNow(true)
          } else setActiveStep(GIFT)
        } else {
          if (walletPurchaseData?.dctStatus === 'active') {
            setReceiverState('ALREADY_ACTIVATED')
          } else {
            setReceiverState('')
            const firstPurchase = async () => {
              if (userLogin) {
                purchaseclient.PurchashesIdGetFeedback(userLogin).then((res: any) => {
                  if (res?.user?.isFirstPurchase === false) {
                    if (walletPurchaseData?.type === PURCHASE_TYPE_BUY) {
                      setActivateNow(true)
                    } else {
                      setActiveStep(GIFT)
                    }
                  } else {
                    setShowPurchaseModal(false)
                    setpostPurchaseSurvey(true)
                  }
                })
              }
            }
            firstPurchase()
          }
        }
      } else {
        if (purchaseType === PURCHASE_TYPE_BUY) {
          setActivateNow(true)
        } else setActiveStep(GIFT)
      }
    } else if (isFinalized === false && isPaymentFailed === true) {
      setReceiverState('FAILURE')
    }
  }, [isFinalized, isPaymentFailed, userLogin])

  useEffect(() => {
    if (paymentError) handleClose()
  }, [paymentError])

  return (
    <>
    {openActivationFailureModal?
    <Box sx={{ marginTop: '5px'}}><ActivationFailure handleClose = { handleClose } messageArray = { [] }/></Box>:    
    <B.CheckBoxWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <B.CheckBoxContainer>
          {isPaymentSuccess && <img src={CheckedIcon} alt="" />}
          {!isPaymentSuccess && <CircularProgress size={20} />}

          <B.CheckBoxText>Confirming payment transaction</B.CheckBoxText>
          {!isPaymentSuccess && retryStatus && <B.RetryText>(Retrying...)</B.RetryText>}
        </B.CheckBoxContainer>
        <B.CheckBoxContainer>
          {(mintStatus === undefined || mintStatus === 'init' || (mintStatus === 'failed' && mintRetryCount < 4)) && (
            <CircularProgress size={20} />
          )}
          {mintStatus === 'success' && <img src={CheckedIcon} alt="" />}
          {mintStatus === 'failed' && mintRetryCount >= 4 && <img src={UnCheckedIcon} alt="" />}

          <B.CheckBoxText>Minting DCT</B.CheckBoxText>
          {mintStatus === 'failed' && mintRetryCount < 4 && (
            <B.RetryText>{`(Retrying...${mintRetryCount})`}</B.RetryText>
          )}
        </B.CheckBoxContainer>
        {/* <B.CheckBoxContainer>
          {(providerPurchaseStatus === undefined ||
            providerPurchaseStatus === 'init' ||
            (providerPurchaseStatus === 'failed' && purchaseRetryCount < 4)) && <CircularProgress size={20} />}
          {providerPurchaseStatus === 'customerCreated' && <img src={CheckedIcon} alt="" />}
          {providerPurchaseStatus === 'failed' && purchaseRetryCount >= 4 && <img src={UnCheckedIcon} alt="" />}

          <B.CheckBoxText>Generating eSIM</B.CheckBoxText>
          {providerPurchaseStatus === 'failed' && purchaseRetryCount < 4 && (
            <B.RetryText>{`(Retrying...${purchaseRetryCount})`}</B.RetryText>
          )}
        </B.CheckBoxContainer> */}
      </Box>
    </B.CheckBoxWrapper>
    }
    </>
  )
}

export default CheckPurchase
