import React, { FC } from 'react'
import Select, { components, ControlProps, OptionProps } from 'react-select'
import DownArrow from 'assets/icons/DownArrow.svg'
import { useAppContext } from 'contexts/app.context'

interface Props {
  selectedValue: any
  setSelectedValue: any
  options: any
  placeholder?: string
  type?: string
  showIcon?: Boolean
}

const ProviderDropDown: FC<Props> = ({ type, placeholder, selectedValue, setSelectedValue, options, showIcon }) => {
  const { selectedCountry } = useAppContext()
  const disableDropDown =
    (type === 'datalimit' || type === 'provider') && (selectedCountry?.value === '' || selectedCountry === null)

  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <>
        <components.Control {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedValue?.value !== '' && (
              <img
                src={`data:image/png;base64,${selectedValue?.logo}`}
                alt="provider logo"
                style={{ width: '28px', height: '28px' }}
              />
            )}
            <span>{children}</span>
          </div>
          <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    )
  }

  const IconOption = (props: any) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`data:image/png;base64,${props?.data?.logo}`}
          alt="logo"
          style={{ width: '1.75rem', height: '1.75rem', marginRight: '1rem' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  )

  return (
    <>
      <Select
        options={options}
        value={selectedValue?.value && selectedValue}
        onChange={(e) => {
          setSelectedValue(e)
        }}
        isSearchable
        components={{ Control, Option: IconOption }}
        placeholder={placeholder}
        // isClearable
        isDisabled={disableDropDown}
        styles={{
          placeholder: (base:any) => ({
            ...base,
            opacity: '0.8',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: '1.9375rem',
            color: disableDropDown ? '#6F6F73' : '#000000',
            '@media(max-width:600px)': {
              fontSize: '1rem',
            },
          }),
          control: (base:any) => ({
            ...base,
            display: 'flex',
            background: '#ced6e040',
            border: 'none',
            outline: 'none',
            borderRadius: '0.25rem',
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 500,
            fontSize: '1rem',
            color: '#000000',
            cursor: 'pointer',
            padding: '0.2rem 1rem',
            width: type === 'network' ? '100%' : '24.1875rem',
            height: '2.75rem',
            '@media(max-width:600px)': {
              fontSize: '1rem',
              width: '24.1875rem',
              height: '42px',
              padding: '0 1rem',
            },
          }),
          indicatorSeparator: (base:any) => ({
            ...base,
            display: 'none',
          }),
          dropdownIndicator: (base:any) => ({
            ...base,
            display: 'none',
          }),
          container: (base:any) => ({
            ...base,
            maxWidth: '11.375rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media(max-width:600px)': {
              maxWidth: type === 'provider' ? '14.9375rem' : '24.5rem',
            },
          }),
          menu: (base:any) => ({
            ...base,
            maxWidth: type === 'network' ? '100%' : '24.1875rem',
            zIndex: '99',
            '@media(max-width:600px)': {
              maxWidth: '20rem',
            },
          }),
          menuList: (base:any) => ({
            ...base,
            '::-webkit-scrollbar': {
              width: '6px',
              height: '0px',
            },
            '::-webkit-scrollbar-track': {
              background: '#DEE7FD',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#45B549',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#45B549',
            },
          }),
        }}
      />
    </>
  )
}

export default ProviderDropDown
