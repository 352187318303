import React, { FC } from 'react'
import { Box, Step, StepLabel, useMediaQuery } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { S } from '../modal.styles'
import { useWalletContext } from 'contexts/wallet.context'
import { useModalContext } from 'contexts/modal.context'
import {
  BUY_STEPS,
  BUY_STEPS_USED,
  GIFT_STEPS,
  GIFT_STEPS_USED,
  PURCHASE_TYPE_BUY,
  PURCHASE_TYPE_GIFT,
  PURCHASE_TYPE_SINGLE_BUY,
} from 'utils/constants'
import PaymentToggle from 'components/toggle/PaymentToggle'
import DeviceToggle from 'components/toggle/DeviceToggle'
import { usePaymentContext } from 'contexts/payment.context'
import { useAppContext } from 'contexts/app.context'
interface Props {
  isDisable: boolean
}

const Selectors: FC<Props> = ({ isDisable }) => {
  const { purchaseType } = useWalletContext()
  const { activeStep, activateNow } = useModalContext()
  const { paymentSuccess,purchaseOrderType } = usePaymentContext()
  const { selectedOfferType } = useAppContext()

  const purchaseSteps = selectedOfferType
    ? purchaseType === PURCHASE_TYPE_BUY
      ? BUY_STEPS_USED
      : GIFT_STEPS_USED
    : purchaseType === PURCHASE_TYPE_BUY
    ? BUY_STEPS
    : GIFT_STEPS

  return (
    <S.SelectorsWrapper
      displaytoggle={activeStep === 2 || (activeStep === 1 && purchaseType === PURCHASE_TYPE_GIFT)?'true':'false'}
      activatedBuyStyle={paymentSuccess === true && activateNow === true}
      selectedOfferType={selectedOfferType}
    >
      {activeStep === 0 && <PaymentToggle isDisable={isDisable} />}
      {activeStep === 1 && purchaseType === PURCHASE_TYPE_BUY && <DeviceToggle />}
      <S.CustomStepper
        activestepstyle={activeStep === 2?'true':'false'}
        displaytoggle={activeStep === 2 || (activeStep === 1 && purchaseType === PURCHASE_TYPE_GIFT)?'true':'false'}
      >
        {purchaseOrderType === PURCHASE_TYPE_SINGLE_BUY && purchaseSteps.map((step: any, index: any) => {
          return (
            <Step key={step}>
              <StepLabel
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,

                  opacity: `${index !== activeStep ? 0.4 : 1} `,
                }}
                StepIconComponent={CheckComponent}
                StepIconProps={{ active: activeStep === index }}
              >
                {step}
              </StepLabel>
            </Step>
          )
        })}
      </S.CustomStepper>
    </S.SelectorsWrapper>
  )
}

const CheckComponent = (active: any) => {
  if (active.completed) {
    return <CheckIcon sx={{ background: '#00C853', color: 'white', borderRadius: '50%' }} />
  } else {
    return (
      <Box
        sx={{
          background: '#00C853',
          color: 'white',
          borderRadius: '50%',
          height: '1.5rem',
          width: '1.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        {active.icon}
      </Box>
    )
  }
}

export default Selectors
